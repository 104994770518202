import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToasterService } from 'src/app/services/toaster/toaster.service';

@Component({
  selector: 'app-contacts-section',
  templateUrl: './contacts-section.component.html',
  styleUrls: ['./contacts-section.component.scss']
})
export class ContactsSectionComponent implements OnInit {
form: FormGroup; 
  constructor(private fb: FormBuilder,private http: HttpClient,private toaster: ToasterService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
   this.form=  this.fb.group({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: ''
    })
  }

  sendData(){
    let headerDict = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Content-Length' :'',
      'Host' : ''
    }
    let headers = new HttpHeaders(headerDict);
    var body = "firstname=" + this.form.controls.firstName.value + "&lastname=" + this.form.controls.lastName.value
    + "&email=" + this.form.controls.email.value + "&phone=" + this.form.controls.phone.value + "&message=" + this.form.controls.message.value;


    this.http.post(environment.baseUrl + 'send-mail.php' ,body, {headers: headers}).subscribe(res => {
      this.toaster.show('Your message has been sent successfully ', { classname: 'bg-success text-light'});
    }, err => {
      this.toaster.show('Something went wrong,please try again later.', { classname: 'bg-danger text-light'});

    })
  }
}
