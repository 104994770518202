<!-- <div
  class="main-container d-flex flex-column justify-content-center align-items-center w-100"
>
  <p class="text-black font-size-11 font-weight-bolder main-title">
    {{ "about_company" | translate }}
  </p>

  <div
    class="about-content bg-red d-flex row justify-content-around align-items-center flex-wrap w-100"
  >
    <div
      class="d-flex col-lg-3 col-12 justify-content-lg-start justify-content-center mb-lg-0 mb-3"
    >
      <p class="text-white text-center text-lg-left font-size-10 mb-0">
        {{ "multinational_telecom" | translate }}
      </p>
    </div>

    <div
      class="col-lg-6 col-12 d-flex flex-column align-items-center align-items-lg-start"
    >
      <ngb-carousel
        #ngbCarousel
        [showNavigationIndicators]="false"
        [showNavigationArrows]="false"
        (slide)="onChangeCarousel($event)"
      >
        <ng-template ngbSlide *ngFor="let item of carouselContent">
          <p class="text-white font-size-4">{{ item.key | translate }}</p>
        </ng-template>
      </ngb-carousel>

      <div class="mb-2 d-flex align-items-center bar-container">
        <span>{{ slideNumber | number: "2.0" }}</span>
        <ngb-progressbar
          type="success"
          [value]="progressBarVal"
          height="3px"
        ></ngb-progressbar>
        <span>{{ carouselContent.length | number: "2.0" }}</span>
      </div>

      <div class="d-flex">
        <div class="carousel-arrow" (click)="ngbCarousel.prev()">
          <span class="trio-keyboard_arrow_left text-white"></span>
        </div>

        <div class="carousel-arrow mx-3" (click)="ngbCarousel.next()">
          <span class="trio-keyboard_arrow_right text-white"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="our-team-wrapper">
    <div class="our-team-content">
      <h2 class="text-black font-size-11 font-weight-bolder main-title">
        {{ "our_team" | translate }}
      </h2>

      <p class="text-black font-size-4 team-description">
        {{ "team_description" | translate }}
      </p>
    </div>
  </div>
</div> -->
<div id="about-trio">
  <img class="trio-logo" src="../../../assets/images/logo.png" />
  <div class="about-trio-content">
    <h4 class="about-trio-title">
      An international telecom integrated solutions provider
    </h4>
    <p class="about-trio-description">
      {{ "about_carousel_content1" | translate }}&nbsp;
      {{ "about_carousel_content2" | translate }}&nbsp;
      {{ "about_carousel_content3" | translate }}&nbsp;
      {{ "about_carousel_content4" | translate }}
    </p>
    <h4 class="about-trio-title">
      {{ "our_team" | translate }}
    </h4>
    <p class="about-trio-description">
      {{ "team_description" | translate }}
    </p>
  </div>
</div>
<!-- <div class="our-team-wrapper">
  <div class="our-team-content">
    <h2 class="text-black font-size-11 font-weight-bolder main-title">
      {{ "our_team" | translate }}
    </h2>

    <p class="text-black font-size-4 team-description">
      {{ "team_description" | translate }}
    </p>
  </div>
</div> -->
