<div
  class="main-container d-flex flex-column justify-content-center align-items-center w-100 position-relative"
>
  <p class="text-black font-size-11 font-weight-bolder main-title products-title">
    {{ "our_products" | translate }}
  </p>


  <div class="overlay w-100">
    <div class="d-flex flex-column w-100">
        <ngb-carousel #ngbCarouselProducts [showNavigationIndicators]="false" 
        [showNavigationArrows]="false" (slide)="onChangeSlider($event)"> 
            <ng-template ngbSlide *ngFor="let item of products">
              
                <div class="products-wrapper  d-flex align-items-center" [ngStyle]="{'background-image': 'url(' + item.img + ')'}">
                    <div class="col-lg-6 col-10 content-wrapper">
                        <p class="text-red font-size-6">{{item.title | translate}}</p>
        
                        <p class="text-white font-size-4">{{item.description | translate}}</p>

                        <div class="mb-2 d-flex align-items-center bar-container">
                            <span>{{slideNumber |  number: '2.0'}}</span>
                              <ngb-progressbar type="success" [value]="progressBarVal" height="3px"></ngb-progressbar>
                              <span>{{products.length |  number: '2.0'}}</span>
                          </div>
                
                          <div class="d-flex">
                              <div class="carousel-arrow" (click)="ngbCarouselProducts.prev()">
                                  <span class="trio-keyboard_arrow_left text-white"></span>
                              </div>
                    
                              <div class="carousel-arrow mx-3" (click)="ngbCarouselProducts.next()">
                                <span class="trio-keyboard_arrow_right text-white"></span>
                            </div>
                          </div>
                    </div>
                    <div class="col-lg-6 col-12">
                        <!-- <img [src]="item.img"> -->
                    </div>

                </div>
            
            </ng-template>
          
          </ngb-carousel>

        
    </div>
  </div>


</div>
