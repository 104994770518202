<!-- <div
  class="main-container d-flex flex-column justify-content-center align-items-center w-100"
>
  <p class="text-black font-size-11 font-weight-bolder main-title">
    {{ "our_services" | translate }}
  </p>

  <div class="row">
    <div
      class="col-lg-6 col-12 d-flex flex-column"
      *ngFor="let item of servicesContent"
    >
      <img [src]="item.img" height="312px" class="w-100" />
      <p class="text-red mt-2 mb-1 font-size-6 px-lg-0 px-2">
        {{ item.title | translate }}
      </p>
      <p class="font-weight-bold mb-1 px-lg-0 px-2">
        {{ item.header | translate }}
      </p>
      <p class="px-lg-0 px-2">{{ item.description | translate }}</p>
    </div>

    <div class="col-6"></div>
  </div>
</div> -->
<div id="services-wrapper">
  <h1 class="text-black font-size-11 font-weight-bolder main-title text-center">
    Our Services
  </h1>
  <div
    class="uk-position-relative uk-visible-toggle uk-light service-slider"
    tabindex="-1"
    uk-slider
  >
    <ul
      class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m"
    >
      <li
        *ngFor="let item of servicesContent"
        class="uk-card uk-card-default uk-card-hover uk-card-body service-card"
        [ngClass]="{
          expanded: item.shouldShowMore
        }"
      >
        <!-- <img src="assets/images/our-team-image.jpg" alt="" />
        <div class="service-content uk-card-body">
          <h3 class="uk-card-title">{{ item.title | translate }}</h3>
          <p>{{ item.description | translate }}</p>
        </div>
        <div class="service-footer uk-card-footer"></div> -->
        <div class="service-img">
          <img [src]="item.img" alt="" />
        </div>
        <div class="uk-card-header">
          <div class="uk-flex-middle">
            <div class="uk-width-expand">
              <h3 class="uk-card-title uk-margin-remove-bottom service-title">
                {{ item.title | translate }}
              </h3>
              <p class="uk-text-meta uk-margin-remove-top">
                <time datetime="2016-04-01T19:00">{{
                  item.header | translate
                }}</time>
              </p>
            </div>
          </div>
        </div>
        <div
          [ngClass]="{
            'fade-text-wrap': item.shouldShowMore,
            'uk-card-body': true
          }"
        >
          <p [ngClass]="{ 'fade-text': !item.shouldShowMore }">
            {{ item.description | translate }}
          </p>
          <a (click)="readMore(item)" class="uk-button uk-button-text"
            >{{item.shouldShowMore?'Read Less' : 'Read More'}}</a
          >
        </div>
      </li>
    </ul>

    <a
      class="uk-position-center-left uk-position-small uk-hidden-hover"
      href="#"
      uk-slidenav-previous
      uk-slider-item="previous"
    ></a>
    <a
      class="uk-position-center-right uk-position-small uk-hidden-hover"
      href="#"
      uk-slidenav-next
      uk-slider-item="next"
    ></a>
  </div>
</div>
