import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-section',
  templateUrl: './about-section.component.html',
  styleUrls: ['./about-section.component.scss']
})
export class AboutSectionComponent implements OnInit {

  slideNumber: Number = 1;
  progressBarVal:Number = 25;
  carouselContent = [
    {
      key: 'about_carousel_content1'
    },
    {
      key: 'about_carousel_content2'
    },
    {
      key: 'about_carousel_content3'
    },
    {
      key: 'about_carousel_content4'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

  onChangeCarousel(event){
    this.slideNumber = Number(event.current.split('ngb-slide-')[1]) + 1;
    this.progressBarVal = (Number(this.slideNumber) / this.carouselContent.length) *100
  }

}
