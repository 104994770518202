import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-customers-section',
  templateUrl: './customers-section.component.html',
  styleUrls: ['./customers-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomersSectionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
