<div  class="main-container d-flex flex-column justify-content-center align-items-center  w-100">
    <p class="text-black font-size-11 font-weight-bolder main-title">{{'careers_title' | translate}}</p>
    
    <div class="careers-content px-4 py-5 bg-red d-flex row justify-content-around align-items-center flex-wrap w-100">
        <div class="col-lg-5 col-12 d-flex flex-column justify-content-center">
            <p class="text-white font-size-9">{{'join_us_title' | translate}}</p>
            <p class="text-white font-size-4">{{'join_us_desc' | translate}}</p>

        </div>
        <div class="col-lg-4 col-12 d-flex flex-column">
            <form  [formGroup]="careersForm" class=" d-flex flex-column">
                <input class="primary-input mb-3" formControlName="email" type="text" placeholder="{{'your_email' | translate}}">
                <button class="btn-primary-trio bg-white text-red" (click)="sendMail()" >{{'send_now' | translate}}</button>

            </form>
        </div>

    </div>
</div>