import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToasterService } from 'src/app/services/toaster/toaster.service';

@Component({
  selector: 'app-careers-section',
  templateUrl: './careers-section.component.html',
  styleUrls: ['./careers-section.component.scss']
})
export class CareersSectionComponent implements OnInit {
  careersForm: FormGroup;
  constructor(private fb: FormBuilder,private http: HttpClient,private toaster: ToasterService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
    this.careersForm = this.fb.group({
      email: ''
    }) 
  }

  sendMail(){
    let headerDict = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Content-Length' :'',
      'Host' : ''
    }
    let headers = new HttpHeaders(headerDict);
    var body = "firstname=" + "" + "&lastname=" + ""
    + "&email=" + this.careersForm.controls.email.value + "&phone=" + "" + "&message=" + "";


    this.http.post(environment.baseUrl + 'send-mail.php' ,body, {headers: headers}).subscribe(res => {
      this.toaster.show('Your message has been sent successfully ', { classname: 'bg-success text-light'});
    }, err => {
      this.toaster.show('Something went wrong,please try again later.', { classname: 'bg-danger text-light'});
    })
  }

}
