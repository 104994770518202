import { Component, OnInit } from '@angular/core';
import { ChatService, Message } from 'src/app/services/chat/chat.service';
import { Observable } from 'rxjs';
import {scan} from 'rxjs/operators';

@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss']
})
export class ChatDialogComponent implements OnInit {

  messages: Observable<Message[]>;
  formValue: string;
  constructor(public chat: ChatService) { 
    this.messages = this.chat.conversation.asObservable().pipe(
    scan((acc, val) => acc.concat(val))
    )
  }

  ngOnInit(): void {
  }

  sendMessage() {
    this.chat.converse(this.formValue);
    this.formValue = '';
    }
}
