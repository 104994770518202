import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-section',
  templateUrl: './services-section.component.html',
  styleUrls: ['./services-section.component.scss'],
})
export class ServicesSectionComponent implements OnInit {
  array = [1, 2, 3, 4];
  effect = 'scrollx';

  servicesContent = [
    {
      img: '../../../assets/images/drive_test2.jpeg',
      title: 'service1_title',
      header: 'service1_header',
      description: 'service1_desc',
      shouldShowMore: false,
    },
    {
      img: '../../../assets/images/network-optimization-image.png',
      title: 'service2_title',
      header: 'service2_header',
      description: 'service2_desc',
      shouldShowMore: false,
    },
    {
      img: '../../../assets/images/hr-recruitment-image.png',
      title: 'service3_title',
      header: 'service3_header',
      description: 'service3_desc',
      shouldShowMore: false,
    },
    {
      img: '../../../assets/images/network_opt2.jpg',
      title: 'service4_title',
      header: 'service4_header',
      description: 'service4_desc',
      shouldShowMore: false,
    },
    {
      img: '../../../assets/images/repeater-network-image.png',
      title: 'service5_title',
      header: 'service5_header',
      description: 'service5_desc',
      shouldShowMore: false,
    },
    {
      img: '../../../assets/images/in_building2.jpeg',
      title: 'service6_title',
      header: 'service6_header',
      description: 'service6_desc',
      shouldShowMore: false,
    },
    {
      img: '../../../assets/images/network_design2.jpeg',
      title: 'service7_title',
      header: 'service7_header',
      description: 'service7_desc',
      shouldShowMore: false,
    },
    {
      img: '../../../assets/images/telecom-consulting-image.png',
      title: 'service8_title',
      header: 'service8_header',
      description: 'service8_desc',
      shouldShowMore: false,
    },
  ];
  constructor() {}

  ngOnInit(): void {}

  readMore(item) {
    console.log(item)
    item.shouldShowMore = !item.shouldShowMore;
  }
}
