import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ChatDialogComponent } from './components/chat-dialog/chat-dialog.component';
import { ChatService } from './services/chat/chat.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AboutSectionComponent } from './components/about-section/about-section.component';
import { ServicesSectionComponent } from './components/services-section/services-section.component';
import { PartnersSectionComponent } from './components/partners-section/partners-section.component';
import { ProductsSectionComponent } from './components/products-section/products-section.component';
import { CustomersSectionComponent } from './components/customers-section/customers-section.component';
import { CareersSectionComponent } from './components/careers-section/careers-section.component';
import { ContactsSectionComponent } from './components/contacts-section/contacts-section.component';
import { ToasterComponent } from './components/toaster/toaster.component';

@NgModule({
  declarations: [AppComponent, ChatDialogComponent, AboutSectionComponent,
     ServicesSectionComponent, PartnersSectionComponent, 
     ProductsSectionComponent, CustomersSectionComponent, CareersSectionComponent, ContactsSectionComponent, ToasterComponent],
  imports: [
    BrowserModule,
    FormsModule,
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
        defaultLanguage: 'en'
      }
    ),
    ReactiveFormsModule
  ],
  providers: [ChatService],
  bootstrap: [AppComponent],
})

export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}
