import { Component, ViewChild, OnInit, HostListener } from "@angular/core";
import { TranslationService } from "./services/translation/translation.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Trio";
  isHeaderActive = false;
  @HostListener("window:scroll", ["$event"]) onScroll(event) {
    if (window.scrollY > 50) {
      this.isHeaderActive = true;
    } else {
      this.isHeaderActive = false;
    }
  }
  constructor(private translate: TranslationService) {}
  ngOnInit() {
    let aboutSection = document.getElementById("about");

    console.log(aboutSection.hasAttribute("href"));
  }

  changeLang() {
    this.translate.changeLang();
  }
}
