<div  class="main-container d-flex flex-column justify-content-center align-items-center  w-100">
    <p class="text-black font-size-11 font-weight-bolder main-title">{{'contact_us_title' | translate}}</p>
    
    <div class="py-5 d-flex row justify-content-center flex-wrap w-100">
        <div class="form-wrapper d-flex justify-content-center align-items-center w-50">
            <form class="d-flex flex-column align-items-center w-100" [formGroup]="form">
                <div class="d-flex flex-wrap justify-content-between w-100">
                    <div class="col-6 d-flex flex-column pr-2 pl-0">
                        <label class="form-label">{{'first_name' | translate}}</label>
                        <input class="contact-form-input mb-2" formControlName="firstName"
                         placeholder="{{'your_first_name' | translate}}">

                    </div>
                    <div class="col-6 d-flex flex-column px-0">
                        <label class="form-label">{{'last_name' | translate}}</label>
                        <input class="contact-form-input mb-2" formControlName="lastName"
                         placeholder="{{'your_last_name' | translate}}">

                    </div>
                </div>

                <div class="d-flex flex-column w-100">
                    <label class="form-label">{{'email' | translate}}</label>
                    <input class="contact-form-input mb-2" 
                    placeholder="{{'your_email' | translate}}" formControlName="email">
                    
                </div>

                <div class="d-flex flex-column w-100">
                    <label class="form-label">{{'phone' | translate}}</label>
                    <input class="contact-form-input mb-2" formControlName="phone"
                    placeholder="{{'your_phone' | translate}}">
                    
                </div>

                <div class="d-flex flex-column w-100 mb-3">
                    <label class="form-label">{{'message' | translate}}</label>
                    <textarea class="contact-form-input mb-2" rows="4" formControlName="message"
                    placeholder="{{'your_msg' | translate}}"></textarea>
                    
                </div>

                <button class="btn-primary-trio bg-red text-white px-4" (click)="sendData()">{{'send_msg'| translate}}</button>
           
            </form>

        </div>
        <!-- <div class="col-lg-6 col-12 d-flex flex-column">
          <p class="font-size-10 mb-0 mt-lg-0 mt-3 success-title">{{'success_msg' | translate}}</p>
          <p class="font-size-10 success-title">{{'contact_us_today' | translate}}</p>
          <div class="map-wrapper mb-2">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.3352355020434!2d31.3415167145939!3d30.055923824986223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583e7abc5607d1%3A0xd1894218060d6573!2sMahmoud%20Tawfik%20St%2C%20Al%20Manteqah%20as%20Sadesah%2C%20Nasr%20City%2C%20Cairo%20Governorate!5e0!3m2!1sen!2seg!4v1596976540681!5m2!1sen!2seg"   frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

          </div>
          <div class="d-flex align-items-center bg-gray py-1 px-2 mb-2">
              <span class="trio-location font-size-8"></span>
              <p class="mb-0">3 Mahmoud Tawfik St., Nasr City, Cairo, Egypt</p>
          </div>

          <div class="d-flex align-items-center bg-gray py-1 px-2">
            <span class="trio-envelope font-size-7"></span>
            <p class="mb-0 px-2">info@trio-telecom.com</p>
        </div>
        </div> -->

    </div>

    <p class="text-black font-size-11 font-weight-bolder">{{'our_locations' | translate}}</p>
    <div class="d-flex justify-content-between align-items-center flex-wrap w-100 mt-3">
       
        <div class="d-flex flex-column col-md-6 col-12 pr-lg-3 py-lg-0 py-2 px-0 ">
            <div class="mb-1">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3454.138890549713!2d30.96712231511488!3d30.032872981886502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzDCsDAxJzU4LjMiTiAzMMKwNTgnMDkuNSJF!5e0!3m2!1sen!2seg!4v1597347282630!5m2!1sen!2seg" width="100%" height="250px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>

            <p class="text-red mb-0 font-weight-bold mb-2">Egypt</p>
            <div class="d-flex">
                <p class="font-weight-bold mb-0 font-size-5">{{'address' | translate}}: </p>
                <p class="mb-0 mx-1 font-size-4"> V167,8th District, 3rd Neighboring, Sheikh Zayed, Giza, Egypt</p>
            </div>

            <div class="d-flex">
                <p class="font-weight-bold mb-0">{{'phone_number' | translate}}: </p>
                <a class="text-decoration-none text-black" href="tel:+2011 234 4356">
                    <p class="mb-0 mx-1">(+20) 11 234 4356</p>

                </a>
            </div>

            <div class="d-flex">
                <p class="font-weight-bold mb-0">{{'email' | translate}}: </p>
                <p class="mb-0 mx-1">info@trio-telecom.com</p>
            </div>
        </div>
        <div class="d-flex flex-column col-md-6 pl-lg-3 px-0 col-12 py-lg-0 py-2">
            <div class="mb-1">
                
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.4300099985294!2d46.71653931499912!3d24.677740584140427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f047a75234bbd%3A0x4fd37cf9f040b08a!2s7443%20King%20Abdul%20Aziz%20Rd%2C%20Ad%20Dhubbat%2C%20Riyadh%2012625%C2%A03931%2C%20Saudi%20Arabia!5e0!3m2!1sen!2seg!4v1597440695651!5m2!1sen!2seg" width="100%" height="250px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>

            <p class="text-red mb-0 font-weight-bold mb-2">Saudi Arabia</p>
            <div class="d-flex">
                <p class="font-weight-bold mb-0 font-size-5">{{'address' | translate}}: </p>
                <p class="mb-0 mx-1 font-size-4"> 7417 King Abdulaziz Road, Al Yasmine, Riyadh, KSA</p>
            </div>

            <div class="d-flex">
                <p class="font-weight-bold mb-0">{{'phone_number' | translate}}: </p>
                <a class="text-decoration-none text-black" href="tel:+966550215266">

                    <p class="mb-0 mx-1">+966550215266</p>
                </a>
            </div>

            <div class="d-flex">
                <p class="font-weight-bold mb-0">{{'email' | translate}}: </p>
                <p class="mb-0 mx-1">info@trio-telecom.com</p>
            </div>
        </div>

    


     

    </div>
</div>


