import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products-section',
  templateUrl: './products-section.component.html',
  styleUrls: ['./products-section.component.scss']
})
export class ProductsSectionComponent implements OnInit {

  slideNumber: Number = 1;
  progressBarVal:Number = 25;
  products = [
    {
      img: '../../../assets/images/product1.jpeg',
      title: 'product1_title',
      description: 'product1_desc'
    },
    {
      img: '../../../assets/images/product2.jpg',
      title: 'product2_title',
      description: 'product2_desc'
    },
    {
      img: '../../../assets/images/product3.jpg',
      title: 'product3_title',
      description: 'product3_desc'
    },
    {
      img: '../../../assets/images/product4.jpg',
      title: 'product4_title',
      description: 'product4_desc'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

  onChangeSlider(event){
    this.slideNumber = Number(event.current.split('ngb-slide-')[1]) - 4 + 1;
    this.progressBarVal = (Number(this.slideNumber) / this.products.length) *100
  }
}
