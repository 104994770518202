import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiAiClient } from "api-ai-javascript/es6/ApiAiClient";

@Injectable({
  providedIn: "root",
})
export class Message {
  constructor(@Inject(String)public content: string, @Inject(String)public sentBy: string) {}
}
export class ChatService {
   token = environment.dialogFlow.key;
   client = new ApiAiClient({ accessToken: this.token });
  conversation = new BehaviorSubject<Message[]>([]);

  constructor() {}

  converse(msg: string) {
    const userMessage = new Message(msg, "user");

    this.update(userMessage);

    return this.client
      .textRequest(msg)

      .then((res) => {
        const speech = res.result.fulfillment.speech;

        const botMessage = new Message(speech, "bot");

        this.update(botMessage);
      });
  }

  update(msg: Message) {
    this.conversation.next([msg]);
  }
}
