import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-partners-section',
  templateUrl: './partners-section.component.html',
  styleUrls: ['./partners-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PartnersSectionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
