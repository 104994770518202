<!-- <h1>Angular Bot</h1>
<ng-container *ngFor="let message of messages | async">
<div class="message" [ngClass]="{ 'from': message.sentBy === 'bot',
'to': message.sentBy === 'user' }">
{{ message.content }}
</div>
</ng-container>
 
<label for="nameField">Your Message</label>
<input [(ngModel)]="formValue" (keyup.enter)="sendMessage()" type="text">
<button (click)="sendMessage()">Send</button> -->

<iframe
    allow="microphone;"
    width="350"
    height="430"
    src="https://console.dialogflow.com/api-client/demo/embedded/83ef6c9a-4b0b-4a8d-bc21-6c6a9e063e2e">
</iframe>