import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster/toaster.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {

  constructor(public toastService: ToasterService) { }

  ngOnInit(): void {
  }
  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}
