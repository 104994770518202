import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  lang: string;
  private renderer: Renderer2;
  private isArabic = new BehaviorSubject<boolean>(false);
  isArabic$ = this.isArabic.asObservable();
  constructor(
    private translate: TranslateService,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.init();
  }

  init() {
    const currentLang = localStorage.getItem("userLang");
    if (!currentLang) {
      this.lang = environment.lang;
      localStorage.setItem("userLang", "en");
    } else {
      this.lang = localStorage.getItem("userLang");
    }
    this.translate.use(this.lang);
    if (this.lang === "ar") {
      this.renderer.addClass(document.body, "rtl");
      this.isArabic.next(true);
    } else {
      this.renderer.removeClass(document.body, "rtl");
      this.isArabic.next(false);
    }
  }

  changeLang() {
    this.lang = this.lang === "ar" ? "en" : "ar";
    localStorage.setItem("userLang", this.lang);
    this.translate.use(this.lang);
    if (this.lang === "ar") {
      this.renderer.addClass(document.body, "rtl");
      this.isArabic.next(true);
    } else {
      this.renderer.removeClass(document.body, "rtl");
      this.isArabic.next(false);
    }
  }
}
