// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  dialogFlow: {
    key: '83ef6c9a-4b0b-4a8d-bc21-6c6a9e063e2e'
  },
  lang: 'en',
  SENDGRID_API_KEY: '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',
  baseUrl :'http://www.trio-telecom.com/' 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
