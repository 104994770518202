<div
  class="main-container d-flex flex-column justify-content-center align-items-center w-100"
>
  <p class="text-black font-size-11 font-weight-bolder main-title">
    {{ "our_customers" | translate }}
  </p>

  <div class="d-flex justify-content-lg-around justify-content-center align-items-center w-100 mt-4 mb-3 flex-wrap">
    <img src="../../../assets/images/vodafone-logo.png" class="my-3 my-md-0 mx-lg-0 mx-3"/>
    <img src="../../../assets/images/ericsson-logo.png" class="my-3 my-md-0 mx-lg-0 mx-3" />
    <img src="../../../assets/images/we-logo.png" class="my-3 my-md-0 mx-lg-0 mx-3" />
    <img src="../../../assets/images/nokia-logo.png" class="my-3 my-md-0 mx-lg-0 mx-3"/>
    <img src="../../../assets/images/orange-logo.png" class="my-3 my-md-0 mx-lg-0 mx-3" />
    <img src="../../../assets/images/stc-logo.png" class="my-3 my-md-0 mx-lg-0 mx-3"/>
    <img src="../../../assets/images/zain-logo.png" class="my-3 my-md-0 mx-lg-0 mx-3" />
    <img src="../../../assets/images/mobily-logo.png" class="my-3 my-md-0 mx-lg-0 mx-3"/>

  </div>
</div>
