<div
  class="main-container d-flex flex-column justify-content-center align-items-center w-100"
>
  <p class="text-black font-size-11 font-weight-bolder main-title">
    {{ "our_partners" | translate }}
  </p>

  <p class="text-black font-size-8 w-75 text-center partner-description">
    “ {{ "partners_desc" | translate }} “
  </p>
  <div class="d-flex  justify-content-center
   justify-content-md-around align-items-center w-100 mt-2 mb-3 flex-wrap px-lg-0 px-3">
    <img src="../../../assets/images/Amphenol-logo.png" class="my-2 mx-2 mx-md-0 my-md-0" />
    <img src="../../../assets/images/qualcumm-logo.png"class="my-2  mx-2 mx-md-0 my-md-0" />
    <img src="../../../assets/images/actix-logo.png" class="my-2  mx-2 mx-md-0 my-md-0"/>
    <img src="../../../assets/images/ascom-logo.png" class="my-2  mx-2 mx-md-0 my-md-0"/>
  </div>
</div>
