<div class="d-flex flex-column">
  <section id="intro" class="intro-section position-relative">
    <div class="overlay">
      <div
        class="d-flex header py-2 px-lg-0 pl-3 justify-content-lg-around justify-content-between"
        [ngClass]="{ active: isHeaderActive }"
      >
        <div
          class="logo d-flex flex-column justify-content-center align-items-center"
        >
          <a href="http://www.trio-telecom.com/">
            <img src="../assets/images/trio-logo.png" />
          </a>
        </div>
        <div class="d-flex align-items-center main-container mx-lg-0 mx-3">
          <ul class="navbar-menu mb-0 px-0 d-none d-lg-block">
            <li>
              <a href="#about">{{ "about_us" | translate }}</a>
            </li>
            <li>
              <a href="#services">{{ "services" | translate }}</a>
            </li>
            <li>
              <a href="#partners">{{ "partners" | translate }}</a>
            </li>
            <li>
              <a href="#products">{{ "products" | translate }}</a>
            </li>
            <li>
              <a href="#customers">{{ "customers" | translate }}</a>
            </li>
            <li>
              <a href="#careers">{{ "careers" | translate }}</a>
            </li>
            <li>
              <a href="#contacts">{{ "contact_us" | translate }}</a>
            </li>
          </ul>

          <div ngbDropdown>
            <button
              type="button"
              class="btn d-flex d-lg-none p-0 hamburger-menu-btn"
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              <span class="trio-menu text-white trio-font-size-11"></span>
            </button>

            <div
              ngbDropdownMenu
              aria-labelledby="dropdownBasic1"
              class="m-0 p-0 dropdown-menu justify-content-center"
              display="dynamic"
            >
              <button ngbDropdownItem>
                <a class="text-decoration-none dropdown-item" href="#about">{{
                  "about_us" | translate
                }}</a>
              </button>
              <button ngbDropdownItem>
                <a
                  class="text-decoration-none dropdown-item"
                  href="#services"
                  >{{ "services" | translate }}</a
                >
              </button>
              <button ngbDropdownItem>
                <a
                  class="text-decoration-none dropdown-item"
                  href="#partners"
                  >{{ "partners" | translate }}</a
                >
              </button>
              <button ngbDropdownItem>
                <a
                  class="text-decoration-none dropdown-item"
                  href="#products"
                  >{{ "products" | translate }}</a
                >
              </button>
              <button ngbDropdownItem>
                <a
                  class="text-decoration-none dropdown-item"
                  href="#customers"
                  >{{ "customers" | translate }}</a
                >
              </button>
              <button ngbDropdownItem>
                <a class="text-decoration-none dropdown-item" href="#careers">{{
                  "careers" | translate
                }}</a>
              </button>
              <button ngbDropdownItem>
                <a
                  class="text-decoration-none dropdown-item"
                  href="#contacts"
                  >{{ "contact_us" | translate }}</a
                >
              </button>
            </div>
          </div>

          <!-- <div class="text-white d-flex justify-content-end lang-container">
      <p class="mb-0" (click)="changeLang()">{{'lang' | translate}}</p>
  </div> -->
        </div>
      </div>
      <div
        class="d-flex flex-column description-container justify-content-center align-items-center"
      >
        <p class="main-title text-center">{{ "main_title" | translate }}</p>
        <p class="text-white description text-center px-md-0 px-3">
          {{ "description" | translate }}
        </p>
      </div>

      <div
        class="explore-trio d-flex flex-column justify-content-center align-items-center"
      >
        <a class="text-red mb-2 text-decoration-none" href="#about">
          {{ "explore" | translate }}
        </a>
        <img src="../assets/images/scroll-down-icon.png" width="18px" />
      </div>
    </div>

    <div class="h-100">
      <video
        width="100%"
        height="100%"
        loop="true"
        autoplay="autoplay"
        [muted]="true"
      >
        <source src="../assets/videos/Trio-Intro-Video_Trim.mp4" />
      </video>
    </div>
  </section>

  <section id="about">
    <app-about-section></app-about-section>
  </section>

  <section id="services" class="px-lg-5 px-3 mt-5 mb-2">
    <app-services-section></app-services-section>
  </section>

  <section id="products" class="mt-5">
    <app-products-section></app-products-section>
  </section>

  <section id="partners" class="px-lg-5 px-3">
    <app-partners-section></app-partners-section>
  </section>

  <section id="customers" class="px-lg-0 px-3 mb-2">
    <app-customers-section></app-customers-section>
  </section>

  <section id="careers" class="px-lg-5 px-3 mt-5 mb-2">
    <app-careers-section></app-careers-section>
  </section>

  <section id="contacts" class="px-lg-5 px-3 mt-5 mb-5">
    <app-contacts-section></app-contacts-section>
  </section>

  <section id="footer" class="footer position-relative">
    <div
      class="w-100 footer-wrapper flex-md-row flex-column d-flex justify-content-between align-items-center px-3 py-2"
    >
      <div
        class="text-wrapper d-flex align-items-center flex-wrap justify-content-center justify-content-lg-start w-100 px-lg-5"
      >
        <p class="font-size-4 mb-0 mx-3">{{ "privacy" | translate }}</p>
        <p class="font-size-4 mb-0 mx-2">{{ "terms" | translate }}</p>
        <p class="font-size-3 mb-0 mx-lg-5 mx-md-3 mx-1 copyright">
          &copy; {{ "copyright" | translate }}
        </p>
      </div>

      <div
        class="social-icons d-flex justify-content-center align-items-center my-md-0 my-2"
      >
        <div class="icon-wrapper mx-2">
          <a
            class="text-decoration-none d-flex justify-content-center"
            href="https://www.linkedin.com/company/trio-telecom/"
            target="_blank"
          >
            <span class="trio-linkedin text-black"> </span>
          </a>
        </div>

        <div class="icon-wrapper mx-2">
          <a
            class="text-decoration-none d-flex justify-content-center"
            href="https://www.facebook.com/TrioTelecomEgypt"
            target="_blank"
          >
            <span class="trio-social-facebook text-black"> </span>
          </a>
        </div>

        <!-- <div class="icon-wrapper mx-2">
      <span class="trio-social-twitter"></span>
    </div> -->
      </div>
    </div>

    <a href="#intro" class="top-arrow">
      <span class="trio-arrow-up"> </span>
    </a>
  </section>
</div>
